import React, { Component } from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { ELEMENTS } from '../../../utils';
import {
  IPlayer,
  IVisibility,
  ISlide,
  IElement,
  Status,
} from '../../../redux/modules';
import { Button, StatusTag } from '../../../components';
import {
  Container,
  ElementsSection,
  EditorWrapper,
  NameSection,
  SectionTitle,
  SectionContent,
  CustomNameInput,
  Divider,
  ScheduleSection,
  LocationSection,
  HeaderContainer,
  HeaderRightSide,
  TitleCreative,
} from './styles';

import Actions from './Actions';
import LocationSelector from './LocationSelector';
import ScheduleSelector from './ScheduleSelector';
import { ImageElement, VideoElement, QrElement, TextElement } from './Elements';

interface IProps {
  creativityId: string;
  updateElementProp?: any;
  updateCreativity: any;
  updateTitle: any;
  updatePlayers: any;
  changeSelection: any;
  visibility?: IVisibility;
  creativityTitle: any;
  intl: InjectedIntl;
  slide: ISlide;
  emergency: any;
  status: Status;
  playersCreativity: IPlayer[];
  players: IPlayer[];
  hasPublishedEmergency: boolean;
  hasAllPlayersSelected: boolean;
  isPublished?: boolean;
  deleteCreativity: any;
  selected?: boolean | undefined;
  auth?: any;
  handlePlay?: any;
  startDate?: string;
  endDate?: string;
}

interface IState {
  disabledImage: boolean;
  active?: boolean;
  value?: string;
  error?: boolean;
}

class Menu extends Component<IProps, IState> {
  state: IState = {
    disabledImage: false,
  };

  renderElement = (element: IElement, index: number) => {
    const { creativityId, status } = this.props;
    if (element.locked) {
      return null;
    }

    switch (element.type) {
      case ELEMENTS.TEXT:
        return (
          <TextElement
            key={index}
            index={index}
            creativeId={creativityId}
            element={element}
            status={status}
          />
        );
      case ELEMENTS.IMAGE:
        return (
          <ImageElement
            key={index}
            creativeId={creativityId}
            element={element}
            index={index}
            status={status}
          />
        );
      case ELEMENTS.QR:
        return (
          <QrElement
            key={index}
            index={index}
            creativeId={creativityId}
            element={element}
            status={status}
          />
        );
      case ELEMENTS.VIDEO:
        return (
          <VideoElement
            key={index}
            creativeId={creativityId}
            element={element}
            index={index}
            status={status}
          />
        );
      case ELEMENTS.WEATHER:
        return null;
      default:
        return null;
    }
  };

  render() {
    const {
      creativityId,
      creativityTitle,
      intl,
      slide,
      emergency,
      status,
      selected,
      hasAllPlayersSelected,
      endDate,
      startDate,
    } = this.props;
    return (
      <Container>
        <HeaderContainer>
          <TitleCreative>{creativityTitle}</TitleCreative>
          <HeaderRightSide>
            <Button
              onClick={this.props.handlePlay()}
              type="preview"
              button={intl!.formatMessage({
                id: 'actions.buttons.play',
              })}
              padding={8}
              icon="play-circle"
            />
            {!emergency && (
              <div>
                <StatusTag status={status} />
              </div>
            )}
          </HeaderRightSide>
        </HeaderContainer>
        <EditorWrapper>
          <form>
            {!emergency && (
              <NameSection>
                <SectionTitle>
                  <FormattedMessage id={'editor.info.field.creativity'} />
                </SectionTitle>
                <SectionContent>
                  <CustomNameInput
                    disabled={
                      status === Status.PUBLISHED ||
                      status === Status.PENDING_VALIDATION
                    }
                    value={creativityTitle || ''}
                    placeholder={'editor.info.placeholer.creativity'}
                    onChange={event =>
                      this.props.updateTitle(
                        this.props.creativityId,
                        'creativity',
                        event.target.value
                      )
                    }
                  />
                </SectionContent>
              </NameSection>
            )}
            <Divider />

            <ScheduleSection>
              <SectionTitle>
                <FormattedMessage id="editor.info.screen.titleActivity" />
              </SectionTitle>
              <SectionContent>
                {!emergency && (
                  <ScheduleSelector
                    visibility={this.props.visibility}
                    creativityId={creativityId}
                    status={status}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              </SectionContent>
            </ScheduleSection>

            <Divider />

            <LocationSection>
              <LocationSelector
                id={creativityId}
                status={status}
                hasAllPlayersSelected={hasAllPlayersSelected}
              />
            </LocationSection>

            <Divider />

            <ElementsSection>
              <SectionTitle style={{ marginBottom: '10px' }}>
                <FormattedMessage id={'editor.info.elements.sectionTitle'} />
              </SectionTitle>
              {slide &&
                slide.elements.map((item, i) => this.renderElement(item, i))}
            </ElementsSection>

            <Divider />

            <Actions
              id={creativityId}
              isEmergency={emergency}
              status={status}
              selected={selected}
              endDate={endDate}
            />
          </form>
        </EditorWrapper>
      </Container>
    );
  }
}

export default injectIntl(Menu);
